/* Services.css */
.services {
  text-align: center;
}

.services h2 {
  margin-bottom: 20px;
}

.services-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}
