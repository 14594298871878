/* ServiceBox.css */
.service-box {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  width: 200px;
  text-align: center;
}

.service-box img {
  width: 100%;
  border-radius: 5px;
  margin-bottom: 10px;
}
