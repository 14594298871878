/* Navbar.css */
.navbar {
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  text-align: center;
}

.navbar h2 {
  margin: 0;
}
