/* App.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
}

section {
  padding: 20px;
  text-align: center;
}

h1,
h2,
h3 {
  margin-bottom: 10px;
}

p {
  margin-bottom: 20px;
}
